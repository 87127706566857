import React from "react"
import { Box, Grid, Container, Stack } from "@mui/material"
import { Textarea } from "@parallel-domain/pd-theme"

// import app components
import { Image } from "../../../submodules/components/image"
import { Headline } from "../../../submodules/components/headline"

const TextImageWithOffset = props => {
  const { items } = props

  return (
    <Box sx={{ py: [3, 4, 5] }}>
      <Container maxWidth="lg">
        <Stack spacing={[8, 14]}>
          {items &&
            items.map((item, index) => {
              const { title, text, image } = item

              const isEven = index % 2 === 0

              return (
                <Box key={index}>
                  <Grid
                    container
                    alignItems="center"
                    spacing={[2, 4]}
                    sx={{ maxWidth: "100%" }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={5}
                      sx={{ width: "100%", order: 2 }}
                    >
                      {title && <Headline {...title} />}

                      {text && <Textarea>{text}</Textarea>}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={7}
                      sx={{ order: { xs: 1, md: isEven ? 3 : 1 } }}
                    >
                      <Box
                        sx={{
                          transform: {
                            xl: "scale(1.2)",
                          },
                          transformOrigin: isEven
                            ? "center left"
                            : "center right",
                        }}
                      >
                        {image && <Image {...image} />}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              )
            })}
        </Stack>
      </Container>
    </Box>
  )
}

export default TextImageWithOffset
